import './cliente.css';
import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useEffect } from 'react';
import logoInv from './images/logoInv.png';
import logo from './images/logo.png'
import homeBGA1 from './images/homeBGA1.png';
import homeBGA2 from './images/homeBGA2.png';
import homeBGA3 from './images/homeBGA3.png';
import homeBGA4 from './images/homeBGA4.png';
import homeBGA5 from './images/homeBGA5.png';
import homeBGA6 from './images/homeBGA6.png';
import logoM1 from './images/logoM1.png'
import logoM2 from './images/logoM2.png'
import logoM3 from './images/logoM3.png'
import logoM4 from './images/logoM4.png'
import logoM5 from './images/logoM5.png'
import iconFB from './images/iconFB.png';
import iconM from './images/iconM.png';
import upButton from './images/upButton.png';

function Client() {
  const [token, setToken] = useState("");
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const setTokenFunc = (getToken) => {
    setToken(getToken);
  };

  const bodyScrollEvent = (event) => {
    if (document.getElementById("header").getBoundingClientRect().top < 0) {
      document.getElementById("up-sc-btn").style.display = "block";
    } else {
      document.getElementById("up-sc-btn").style.display = "none";
    }
  };

  useEffect(() => {
    document.getElementById('header').scrollIntoView({ behavior: 'smooth', block: 'center' });
    Array.from(document.getElementsByTagName("html")[0].getElementsByClassName("animated")).forEach(e => e.style.visibility = "hidden");
    let observer = new IntersectionObserver((e) => {
        if(!e[0].isIntersecting) {
            observer.unobserve(e[0].target);
            Array.from(e[0].target.nextElementSibling.getElementsByClassName("animated")).forEach(el => {
                el.style.visibility = "visible";
                el.classList.add("animate__animated");
                if(el.classList.contains("fadeInUp")) { el.classList.add("animate__fadeInUp"); }
                if(el.classList.contains("zoomIn")) { el.classList.add("animate__zoomIn"); }
                if(el.classList.contains("fadeInLeft")) { el.classList.add("animate__fadeInLeft"); }
                if(el.classList.contains("bounceIn")) { el.classList.add("animate__bounceIn"); }
                if(el.classList.contains("heartBeat")) { el.classList.add("animate__heartBeat"); }
            });
        }
    }, { rootMargin: "-40%" });//-50%
    observer.observe(document.getElementById("home"));
    observer.observe(document.getElementById("about"));
    observer.observe(document.getElementById("brands"));
    observer.observe(document.getElementById("contact"));

    window?.addEventListener("scroll", bodyScrollEvent);

    emailjs.init({ publicKey: process.env.REACT_APP_EMAILJS_KEY });

    return () => {
      observer.unobserve(document.getElementById("home"));
      observer.unobserve(document.getElementById("about"));
      observer.unobserve(document.getElementById("brands"));
      observer.unobserve(document.getElementById("contact"));
      window?.removeEventListener("scroll", bodyScrollEvent);
    }
  }, []);

  return (
    <React.Fragment>
      <header id='header'>
        <img src={logoInv} alt="Indcommerce" draggable="false" />
        <div>
          <a onClick={() => document.getElementById('about').scrollIntoView({ behavior: 'smooth', block: 'center' })}><p>¿Quiénes somos?</p></a>
          <a onClick={() => document.getElementById('brands').scrollIntoView({ behavior: 'smooth', block: 'center' })}><p>Marcas</p></a>
          <a onClick={() => document.getElementById('contact').scrollIntoView({ behavior: 'smooth', block: 'center' })}><p>Contacto</p></a>
        </div>
        <i
          className="menu-button fa-solid fa-bars"
          style={{'display':'none'}}
          onClick={
            (event) => {
              event.target.parentElement.classList.toggle("header-exp");
            }
          }
        ></i>
      </header>

      <div id='home'>
        <div className='blurFilter'></div>
        <div className='blueFilter'>
          <div>
            <h1 className='animate__animated animate__zoomIn'>Servicio integral</h1>
            <p className='animate__animated animate__fadeInUp anim-delay-200ms'>Insumos y refacciones de todo tipo</p>
            <a 
              className='animate__animated animate__fadeInLeft anim-delay-400ms'
              onClick={() => document.getElementById('about').scrollIntoView({ behavior: 'smooth', block: 'center' })}
            >
              Saber más &nbsp; ➜
            </a>
          </div>
        </div>
        <img className='anim-home animate__animated animate__fadeInLeft anim-delay-1s-400ms' id='ah-1' src={homeBGA1} draggable="false" />
        <img className='anim-home animate__animated animate__fadeInLeft anim-delay-900ms' id='ah-2' src={homeBGA2} draggable="false" />
        <img className='anim-home animate__animated animate__fadeInLeft anim-delay-1s-700ms' id='ah-3' src={homeBGA3} draggable="false" />
        <img className='anim-home animate__animated animate__fadeInLeft anim-delay-1s-100ms' id='ah-4' src={homeBGA4} draggable="false" />
        <img className='anim-home animate__animated animate__fadeInLeft anim-delay-2s' id='ah-5' src={homeBGA5} draggable="false" />
        <img className='anim-home animate__animated animate__fadeInLeft anim-delay-2s-400ms' id='ah-6' src={homeBGA6} draggable="false" />
        {
          (() => {
            setTimeout(() => {
              document.getElementById("ah-1").classList.add("anim-duration-9s");
              document.getElementById("ah-1").classList.add("anim-delay-0s");
              document.getElementById("ah-1").classList.add("anim-home-start");
            }, 2400);
            setTimeout(() => {
              document.getElementById("ah-2").classList.add("anim-duration-7s");
              document.getElementById("ah-2").classList.add("anim-delay-0s");
              document.getElementById("ah-2").classList.add("anim-home-start");
            }, 1900);
            setTimeout(() => {
              document.getElementById("ah-3").classList.add("anim-duration-11s");
              document.getElementById("ah-3").classList.add("anim-delay-0s");
              document.getElementById("ah-3").classList.add("anim-home-start");
            }, 2700);
            setTimeout(() => {
              document.getElementById("ah-4").classList.add("anim-duration-6s");
              document.getElementById("ah-4").classList.add("anim-delay-0s");
              document.getElementById("ah-4").classList.add("anim-home-start");
            }, 2100);
            setTimeout(() => {
              document.getElementById("ah-5").classList.add("anim-duration-7s");
              document.getElementById("ah-5").classList.add("anim-delay-0s");
              document.getElementById("ah-5").classList.add("anim-home-start");
            }, 3000);
            setTimeout(() => {
              document.getElementById("ah-6").classList.add("anim-duration-10s");
              document.getElementById("ah-6").classList.add("anim-delay-0s");
              document.getElementById("ah-6").classList.add("anim-home-start");
            }, 3400);
          })()
        }
      </div>

      <div id='about'>
        <div className='left'>
          <div className="anim-side-image-container">
            <div className="image-container animated fadeInLeft anim-delay-500ms anim-duration-1s-500ms"></div>
            <div className="bg-element animated zoomIn anim-delay-200ms" id="bge-1"></div>
            <div className="bg-element animated zoomIn anim-delay-400ms" id="bge-2"></div>
            <div className="animated anim-element anim-delay-300ms anim-duration-12s" id="ae-1"></div>
            <div className="animated anim-element anim-delay-400ms anim-duration-9s" id="ae-2"></div>
            <div className="animated anim-element anim-delay-500ms anim-duration-16s" id="ae-3"></div>
            <div className="animated anim-element anim-delay-600ms anim-duration-7s" id="ae-4"></div>
          </div>
        </div>
        <div className='right'>
          <img className='animated zoomIn' src={logo} draggable="false" />
          <h2 className='animated fadeInUp'><b>¿</b>Quiénes somos<b>?</b></h2>
          <p className='animated fadeInUp anim-delay-700ms'>
            Indcommerce es una empresa que se dedica a ofrecer un servicio único e integral de localización, compra y traslado de insumos y refacciones de todo tipo, incluyendo trámites de importación y especializándose en el material de difícil adquisición.
            <br/>
            <br/>
            Somos una empresa que conoce la importancia de suministrar material de calidad en tiempo y forma, por lo que hemos logrado alianzas estratégicas con marcas de fabricantes de nivel mundial.
          </p>
        </div>
      </div>

      <div id='brands'>
        <div className='title'>
          <h1>Marcas</h1>
          <p>Estas son algunas marcas de nuestros proveedores</p>
        </div>
        <div className='table'>
          <div className='row'>
            <img className='animated zoomIn anim-delay-200ms' src={logoM1} alt="JESCO" draggable="false" />
            <img className='animated zoomIn anim-delay-600ms' src={logoM2} alt="HO-MATIC" draggable="false" />
            <img className='animated zoomIn anim-delay-1s' src={logoM3} alt="GENESA" draggable="false" />
          </div>
          <div className='row'>
            <img className='animated zoomIn anim-delay-1s-400ms' src={logoM4} alt="RINGSPANN" draggable="false" />
            <img className='animated zoomIn anim-delay-1s-800ms' src={logoM5} alt="MOCK" draggable="false" />
          </div>
        </div>
      </div>

      <div id='contact'>
        <div className='left'>
          <form onSubmit={async (e) => {
            e.preventDefault();
            let info = {
              nombre: document.getElementById("nombre").value,
              correo: document.getElementById("correoElectronico").value,
              telefono: document.getElementById("telefono").value,
              mensaje: document.getElementById("mensaje").value
            };
            try {
              const res = await fetch("https://recaptchaenterprise.googleapis.com/v1/projects/web-indcommerce/assessments?key="+process.env.REACT_APP_SECRET_KEY, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(
                  {
                    "event": {
                      "token": token,
                      "siteKey": process.env.REACT_APP_SITE_KEY,
                    }
                  }
                ),
              });
              const data = await res.json();
              if(data.riskAnalysis.score == 0.7 || data.riskAnalysis.score == 0.9) {
                //-----------------------------------------------------------------------------------------------------------------------
                setIsSubmitting(true);
                emailjs.send(
                    process.env.REACT_APP_SERVICE_ID,
                    process.env.REACT_APP_TEMPLATE_ID,
                    info
                ).then(
                    (result) => {document.getElementById("alert").style.visibility = 'visible';},
                    (error) => {setIsSubmitting(false);console.log(error);}
                );
                e.target.reset();
                //-----------------------------------------------------------------------------------------------------------------------
              } else {
                console.log("Acción denegada!");
              }
            } catch (err) {
              setRefreshReCaptcha(!refreshReCaptcha);
              console.log(err);
            }
          }}>
            <div className='input'>
              <label htmlFor="nombre">Nombre</label>
              <input maxLength="100" type="text" id='nombre' required/>
            </div>
            <div className='input'>
              <label htmlFor="correoElectronico">Correo Electrónico</label>
              <input maxLength="100" type="email" id='correoElectronico' required/>
            </div>
            <div className='input'>
              <label htmlFor="telefono">Teléfono</label>
              <input minLength="10" maxLength="18" type="number" id='telefono' required/>
            </div>
            <div className='input'>
              <label htmlFor="mensaje">Escriba un mensaje</label>
              <textarea maxLength="500" id="mensaje" required></textarea>
            </div>
            <p id='alert' style={{'color':'var(--secondary)','fontSize':'11pt','fontWeight':'600','visibility':'hidden'}}>Gracias, su mensaje se ha enviado, nos pondremos en contacto con usted lo antes posible.</p>
            <button type='submit' className='animated bounceIn anim-duration-1s-500ms' disabled={isSubmitting}>Enviar &nbsp; ➜</button>
            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_SITE_KEY}>
              <GoogleReCaptcha
                className="google-recaptcha-custom-class"
                onVerify={setTokenFunc}
                refreshReCaptcha={refreshReCaptcha}
              />
            </GoogleReCaptchaProvider>
          </form>
        </div>

        <div className='right'>
          <h1 className='animated fadeInLeft anim-delay-500ms anim-duration-2s'>Contáctanos</h1>
          <p className='animated fadeInUp anim-delay-800ms anim-duration-1s-500ms'>Si requiere información sobre nuestros servicios o alguna cotización, rellene nuestro formulario y nos pondremos en contacto con usted lo antes posible.</p>
          <p className='animated fadeInLeft anim-delay-1s-200ms anim-duration-2s'><b>Teléfono:</b>(+52) 22 14 37 57 21</p>
          <p className='animated fadeInLeft anim-delay-1s-300ms anim-duration-2s'><b>Correo:</b>contacto@indcommerce.com.mx</p>
          <div>
            <a><img className='animated zoomIn anim-delay-1s-700ms anim-duration-2s' src={iconFB} draggable="false"/></a>
            <a href="mailto:contacto@indcommerce.com.mx"><img className='animated zoomIn anim-delay-1s-800ms anim-duration-2s' src={iconM} draggable="false"/></a>
          </div>
        </div>
      </div>

      <footer>
        <p>Copyright © 2024 - INDCOMMERCE S.A. All rights reserved <br/> <b>Designed by <a href='https://www.kisystems.com.mx/servicios' target='_blank'>Ki Systems</a></b></p>
        <img src={logoInv} draggable="false" />
      </footer>

      <img
        id="up-sc-btn"
        draggable="false"
        src={upButton}
        className="up-scroll-btn" style={{ display: "none" }}
        onClick={() => window?.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
      />
    </React.Fragment>
  );
}

export default Client;